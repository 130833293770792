import React from "react"
import { Star } from "react-feather"
import { Calendar } from "react-feather"
import { Carousel } from "../Carousel"

export const ReviewsCMS = ({ reviews }) => {
  return (
    <div className="py-20">
      <div>
        <p className="my-0 overline">TESTIMONIALS</p>
        <h2 className="mb-6 fluid-heading-05 lg:text-left">
          <i>Happy</i> patients
        </h2>
      </div>
      <div>
        <Carousel>
          {reviews.map(({ date, stars, message }, index) => (
            <div
              key={index}
              className="flex flex-col h-full px-8 py-12 border-solid border-1 border-borderColour"
            >
              <div className="flex items-center mb-2 text-gold">
                {[...Array(stars)].map((_, starIndex) => (
                  <Star key={starIndex} size="16" className="fill-current" />
                ))}
              </div>
              <p
                className="flex-grow pb-8 font-mono font-thin leading-tight text-blackNew multi-line-truncate"
                style={{ maxHeight: "14rem" }}
              >
                {message}
              </p>
              <div className="flex flex-row items-center content-center mt-1 mb-0 text-blackNew">
                <Calendar size="16" className="mr-1" />
                <p className="mb-0 font-mono text-sm font-semibold tracking-wider text-gray-400 uppercase">
                  {date}
                </p>
              </div>
            </div>
          ))}
        </Carousel>
      </div>
    </div>
  )
}
