import React, { Fragment } from "react"
import { Link } from "gatsby"
import { graphql } from "gatsby"
import Helmet from "react-helmet"

import { Layout } from "../../components/Layout"
import { AffiliateList } from "../../components/AffiliateList"
import { SEO } from "../../components/SEO"
import { ReviewsCMS } from "../../components/ReviewsCMS"
import { BeforeAndAfters } from "../../components/BeforeAndAfters"

import styles from "./product.module.css"
import containerClasses from "../../styles/container.module.css"
import helpers from "../../styles/helpers.module.css"
import buttons from "../../styles/buttons.module.css"

import doctify from "../../assets/images/doctify-logo.svg"
import check32 from "../../assets/icons/check32.svg"
import starRate from "../../assets/images/star_rate.svg"
import check from "../../assets/icons/check.svg"

export default function Template({ data }) {
  const { markdownRemark } = data
  const { frontmatter, html } = markdownRemark
  const {
    title,
    excerpt,
    image,
    imageAlt,
    imageTitle,
    type,
    seoTitle,
    metaDescription,
    bottomBody,
    reviews,
    treatmentAreas,
    treatmentAreasImage,
    concernAreas,
    concernAreasImage,
    beforeAndAfters,
  } = frontmatter
  return (
    <Layout page={type}>
      <SEO title={seoTitle || title} description={metaDescription || excerpt} />
      <Helmet>
        <link rel="preload" href={image} as="image" />
      </Helmet>
      <div className="bg-white">
        <div
          className={`${containerClasses.container} py-12 md:pt-24 lg:pb-12`}
        >
          <div className="col-span-4 md:col-span-8 lg:col-span-6">
            <p className="capitalize overline">
              {type.replace(/([a-z])([A-Z])/g, "$1 $2")}
            </p>
            <h1 className="mt-0 mb-4 fluid-display-02">{title}</h1>
            <p className="mb-0 font-sans text-md">{excerpt}</p>
          </div>
          <div className="flex flex-col col-span-4 mt-0 md:flex-row lg:mt-20 lg:content-center md:col-span-8 lg:col-span-5 lg:col-start-8">
            <Link
              className={`flex items-center justify-center text-center md:flex-grow lg:h-12 lg:grow-0 md:mr-2 md:mt-2 ${buttons.btnLargeExpressive}`}
              to="/contact/"
            >
              <span className="block">Book now</span>
            </Link>
            <a
              className={`flex items-center justify-center text-center md:flex-grow lg:grow-0 lg:h-12 mt-2 ${buttons.btnLargeExpressiveOutline}`}
              href="#faqs"
            >
              <span className="block">Read FAQ&apos;s</span>
            </a>
          </div>
          <div className="flex flex-row items-center content-center col-span-4 mt-0 mb-0 lg:mb-0">
            <div className="flex my-0 mr-2 text-doctifyBright">
              <img
                loading="lazy"
                className="h-5 -mr-1"
                src={starRate}
                alt="Star"
              />
              <img
                loading="lazy"
                className="h-5 -mr-1"
                src={starRate}
                alt="Star"
              />
              <img
                loading="lazy"
                className="h-5 -mr-1"
                src={starRate}
                alt="Star"
              />
              <img
                loading="lazy"
                className="h-5 -mr-1"
                src={starRate}
                alt="Star"
              />
              <img
                loading="lazy"
                className="h-5 mr-2"
                src={starRate}
                alt="Star"
              />
            </div>
            <p className="mb-0 mr-2">
              <span className="block font-bold">Rated 5/5</span>
            </p>
            <img
              loading="lazy"
              className="h-5 mr-1"
              src={doctify}
              alt="Doctify"
            />
          </div>
        </div>
      </div>
      <div className="headerHalfAndHalf">
        <div className={containerClasses.container}>
          <div className="col-span-4 md:col-span-8 lg:col-span-12 aspect-[1/1]">
            <img
              className="object-cover w-full h-full"
              src={image}
              alt={imageAlt}
              title={imageTitle}
            />
          </div>
        </div>
      </div>
      <div className="bg-white2">
        <div
          className={`${containerClasses.container} py-12 md:py-16 lg:py-20 xl:py-24`}
        >
          <div className="col-span-4 md:col-span-6 md:col-start-2 lg:col-span-8 lg:col-start-3">
            <div
              className={`${styles.productContent} lg:col-span-8 lg:col-start-3 xl:col-span-8 xl:col-start-3 `}
              dangerouslySetInnerHTML={{ __html: html }}
            />
          </div>
        </div>
      </div>
      {/* Text Image Section */}
      {treatmentAreas && treatmentAreas?.length > 0 && treatmentAreasImage ? (
        <div className="bg-white">
          <div
            className={`${containerClasses.container} py-12 md:py-24 lg:py-24`}
          >
            {/* Text */}
            <div className="flex flex-col w-full col-span-4 md:col-span-8 lg:justify-center lg:col-span-5">
              <h2 className="mt-0 mb-6 fluid-heading-05">
                <span className="block mb-2 overline">
                  Treatment areas {""}
                </span>
                Which areas can be treated?
              </h2>

              <ul>
                {treatmentAreas.map(({ title, text }, index) => (
                  <li key={index}>
                    <div className="flex items-start w-full">
                      <img
                        loading="lazy"
                        className="w-6 h-6 mx-auto mr-2"
                        src={check}
                        alt="Checkmark"
                      />
                      <p className="flex-1">
                        <span className="font-bold">{title}:</span> {text}
                      </p>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
            {/* Image */}
            <div className="col-span-4 md:col-span-8 lg:col-start-7 lg:col-span-6">
              <img
                loading="lazy"
                className="w-full"
                alt="Dr Hawkes"
                src={treatmentAreasImage}
              />
            </div>
          </div>
        </div>
      ) : null}

      {/* Why choose Dr Hawkes? */}
      <div className="py-16 sm:py-20 md:py-24 bg-white2 ">
        <div className={containerClasses.container}>
          <div className="flex flex-wrap col-span-4 md:col-span-8 lg:col-span-12">
            {/* <!-- Trusted Practitioner --> */}
            <div className="flex flex-col items-center text-center aspect-[3/4] px-8 py-4 w-full lg:w-1/4">
              <div className="flex items-center justify-center w-12 h-12">
                <img loading="lazy" src={check32} alt="Checkmark" />
              </div>

              <h3 className="text-xl italic font-semibold sm:text-2xl">
                Trusted Practitioner
              </h3>
              <p className="text-gray-500">
                Highly experienced and serving in multiple advisory roles, Dr
                Hawkes stays at the forefront of industry standards and care.
              </p>
            </div>

            {/* <!-- Award-Winning --> */}
            <div className="flex flex-col items-center text-center aspect-[3/4] px-8 py-4 w-full lg:w-1/4">
              <div className="flex items-center justify-center w-12 h-12">
                <img loading="lazy" src={check32} alt="Checkmark" />
              </div>
              <h3 className="text-xl italic font-semibold sm:text-2xl">
                Award-Winning
              </h3>
              <p className="text-gray-500">
                Award-winning and Tatter-recommended, Dr Hawkes is honoured to
                be commended for her achievements.{" "}
              </p>
            </div>

            {/* <!-- Bespoke Treatment --> */}
            <div className="flex flex-col items-center text-center aspect-[3/4] px-8 py-4 w-full lg:w-1/4">
              <div className="flex items-center justify-center w-12 h-12">
                <img loading="lazy" src={check32} alt="Checkmark" />
              </div>
              <h3 className="text-xl italic font-semibold sm:text-2xl">
                Bespoke Treatment
              </h3>
              <p className="text-gray-500">
                Using extensive facial and ocular expertise, Dr Hawkes tailors
                each treatment to the individual needs and natural features.{" "}
              </p>
            </div>
            {/* <!-- Advanced Techniques --> */}
            <div className="flex flex-col items-center text-center aspect-[3/4] px-8 py-4 w-full lg:w-1/4">
              <div className="flex items-center justify-center w-12 h-12">
                <img loading="lazy" src={check32} alt="Checkmark" />
              </div>
              <h3 className="text-xl italic font-semibold sm:text-2xl">
                Advanced Techniques
              </h3>
              <p className="text-gray-500">
                State-of-the-art technologies and meticulous care underscore Dr
                Hawkes’ dedication to continuous innovation, learning and
                excellent results.{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* Text Image Section */}
      {concernAreas && concernAreas?.length > 0 && concernAreasImage ? (
        <div className="bg-white">
          <div
            className={`${containerClasses.container} py-12 md:py-24 lg:py-24 grid grid-cols-1 lg:grid-cols-12 gap-6`}
          >
            {/* Image */}
            <div className="col-span-4 md:col-span-8 lg:col-start-1 lg:col-span-6">
              <img
                loading="lazy"
                className="w-full"
                alt="Dr Hawkes"
                src={concernAreasImage}
              />
            </div>
            {/* Text */}
            <div className="flex flex-col w-full col-span-4 md:col-span-8 lg:justify-center lg:col-span-5 lg:col-start-8">
              <h2 className="mt-0 overline">Concerns Treated</h2>
              <h3 className="mt-0 mb-6 fluid-heading-05">
                Which concerns can be addressed?
              </h3>
              <ul>
                {concernAreas.map(({ title, text }, index) => (
                  <li key={index}>
                    <div className="flex items-start w-full">
                      <img
                        loading="lazy"
                        className="w-6 h-6 mx-auto mr-2"
                        src={check}
                        alt="Checkmark"
                      />
                      <p className="flex-1">
                        <span className="font-bold">{title}:</span> {text}
                      </p>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      ) : null}

      {/* Client Testimonials */}
      <Fragment>
        {reviews && reviews?.length > 0 ? (
          <div className="bg-white ">
            <div className={containerClasses.container}>
              <div className="col-span-4 md:col-span-10 lg:col-span-12">
                <ReviewsCMS reviews={reviews} />
              </div>
            </div>
          </div>
        ) : null}
      </Fragment>
      {/* Before and afters */}
      <Fragment>
        {beforeAndAfters && beforeAndAfters?.length > 0 ? (
          <div className="bg-white2">
            <div className={containerClasses.container}>
              <div className="col-span-4 md:col-span-8 lg:col-span-12">
                <BeforeAndAfters beforeAndAfters={beforeAndAfters} />
              </div>
            </div>
          </div>
        ) : null}
      </Fragment>
      {/* Frequently Asked Questions */}
      <div id="faqs">
        <div className="bg-white">
          {bottomBody ? (
            <div
              className={`${containerClasses.container}  py-20 min-h-screen`}
            >
              <div className="top-0 z-10 content-center col-span-4 md:flex md:flex-col md:items-center lg:items-start lg:sticky md:col-span-6 md:col-start-2 lg:col-span-5">
                <h2 className="mt-0 fluid-heading-05 lg:text-left md:text-center">
                  Frequently Asked Questions
                </h2>
                <p className="md:text-center lg:text-left ">
                  Dr Hawkes is available to address your inquiries. Should you
                  have questions, we kindly invite you to reach out.
                </p>
                <Link
                  className={`text-center block lg:inline-block md:w-auto mt-4 md:mt-2 ${buttons.btnLargeExpressiveOutline}`}
                  to="/contact/"
                >
                  <span className="block">Contact</span>
                </Link>
              </div>
              <div className="col-span-4 md:col-span-8 lg:col-span-6 lg:col-start-7">
                <div
                  className={`${styles.productContent} col-span-4 md:col-span-4 lg:col-span-6 `}
                  dangerouslySetInnerHTML={{ __html: bottomBody }}
                />
              </div>
            </div>
          ) : null}
        </div>
      </div>
      <div className="py-12 bg-brilliantWhite md:py-16 lg:py-20 xl:py-24">
        <div className={containerClasses.container}>
          <div className="col-span-4 md:col-span-6 md:col-start-2 lg:col-start-4">
            <h2 className="m-0 text-center fluid-display-04">
              Book a Consultation
            </h2>
            <p className="mt-4 text-center">
              If you would like to book a consultation, please use the form
              below. We will respond within 2 working days.
            </p>
          </div>
          <div className={`${helpers.iframe} col-span-4 md:col-span-12`}>
            <iframe
              width="100%"
              height="100%"
              src="https://crm.pabau.com/modules/lead-form/form.php?compid=10461"
            ></iframe>
          </div>
        </div>
      </div>

      <AffiliateList />
    </Layout>
  )
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        title
        excerpt
        image
        imageAlt
        imageTitle
        type
        seoTitle
        metaDescription
        reviews {
          name
          date(formatString: "MMMM DD, YYYY")
          stars
          message
        }
        treatmentAreas {
          title
          text
        }
        treatmentAreasImage
        concernAreas {
          title
          text
        }
        concernAreasImage
        beforeAndAfters {
          image
        }
        bottomBody
      }
    }
  }
`
